.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.landing-page {
  min-height: 100vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.landing-content {
  max-width: 800px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.App-header {
  margin-bottom: 1rem;
}

.App-header h1 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.subtitle {
  font-size: 1.2rem;
  color: #64748b;
  margin-bottom: 1rem;
}

.features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  width: 100%;
  margin-top: 2rem;
}

.feature {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.feature h3 {
  font-size: 1.25rem;
  color: #1e293b;
  margin: 0;
}

.feature p {
  color: #64748b;
  font-size: 0.875rem;
  margin: 0;
  line-height: 1.5;
}

.dropzone {
  background: white;
  border: 2px dashed #e2e8f0;
  border-radius: 12px;
  padding: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dropzone:hover {
  border-color: #94a3b8;
}

.dropzone.active {
  border-color: #2563eb;
  background: #f8fafc;
}

.upload-hint {
  color: #7f8c8d;
  font-size: 1rem;
  margin-top: 0.5rem;
}

/* Document Workspace Styles */
.document-workspace {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  height: 100vh;
  width: 100%;
  padding: 1rem;
  background: #f8fafc;
}

.preview-panel {
  height: 100%;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.preview-content {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.pdf-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.preview-header {
  border-bottom: 1px solid #e5e7eb;
  padding: 0.75rem 1rem;
  background: #f8fafc;
}

/* Translation Controls Styles */
.translation-controls-container {
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.language-selectors {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.language-select-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.language-select-group label {
  font-weight: 500;
  color: #666;
}

.language-select {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.translate-button {
  padding: 1rem;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.translate-button:hover {
  background-color: #2980b9;
}

.translate-button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

/* Loading Spinner */
.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive Design */
@media (max-width: 768px) {
  .features {
    grid-template-columns: 1fr;
  }
  
  .document-workspace {
    grid-template-columns: 1fr;
  }
  
  .App-header h1 {
    font-size: 2.5rem;
  }
}

.upload-section {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.upload-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.select-button {
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  color: #1f2937;
  transition: all 0.2s ease;
}

.select-button:hover {
  background: #f8fafc;
  border-color: #94a3b8;
}

.preview-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 1rem;
  color: white;
  text-align: center;
  backdrop-filter: blur(4px);
}

.preview-message {
  font-size: 0.875rem;
  font-weight: 500;
}